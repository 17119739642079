import React from 'react'
import Layout from "../../components/App/Layout"
import SEO from '../../components/App/SEO';
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import {graphql} from 'gatsby'
import StorageImg from '../../assets/images/backup/storage.webp'
import ImportImg from '../../assets/images/backup/import.webp'

const Backup = ({data}) => {
    return (
        <Layout env={data.site.siteMetadata.env}>
            <SEO 
                postTitle='Backup your emails | BlueMail App' 
                postDescription='Backup your emails locally with BlueMail to ensure you never lose any important data'
                postImage="/img/OG/og_image_backup.png"
                postURL='features-functions/backup'
                postSEO
            />
            <Navbar />
            <div className='features-functions backup'>
                <div className='container'>
                    <div className='row pt-200'>
                        <div className='col-lg-6 col-md-6 col-xs-12 m-auto'>
                            <h1>BlueMail <span>Backup</span></h1>
                            <p>BlueMail is an excellent choice for an email client for desktop devices that support importing and exporting emails. By following these simple steps, you can backup your emails and ensure that you never lose any important data on your desktop computer. BlueMail also allows users to select specific folders or labels to backup, making it a handy feature for users who only want to backup specific folders or labels instead of their entire email account.</p>
                        </div>
                        <div className='col-lg-6 col-md-6 col-xs-12 m-auto'>
                            <img src={StorageImg} alt='storage' />
                        </div>
                    </div>
                    <div className='row pt-120 mb-4'>
                        <div className='col-lg-12 col-md-12 col-xs-12'>
                            <h2>How to <span>backup</span> your email account or specific folders:</h2>
                        </div>
                    </div>
                    <div className='row pl-3 pr-3'>
                        <div className='col-lg-12 col-md-12 col-xs-12'>
                            <div className='row backup-steps'>
                                <div className='col-lg-1 col-md-1 col-xs-1 text-center number'>
                                    <p className='text-center'><span>1</span></p>
                                </div>
                                <div className='col-lg-11 col-md-11 col-xs-11'>
                                    <p>To backup your emails using BlueMail, you need to export them. To do this, open BlueMail on your desktop and select the email account you want to backup. You can do this by clicking on the email account name in the left-hand panel of the BlueMail interface.</p>
                                </div>
                            </div>
                            <div className='row backup-steps'>
                                <div className='col-lg-1 col-md-1 col-xs-1 text-center number'>
                                    <p className='text-center'><span>2</span></p>
                                </div>
                                <div className='col-lg-11 col-md-11 col-xs-11'>
                                    <p>Once you have selected the email account you want to backup, you can select the specific folders you want to export. You can do this by expanding the folder tree in the left-hand panel of the BlueMail interface and selecting the specific folders you want to backup. Alternatively, you can select all folders in the email account.</p>
                                </div>
                            </div>
                            <div className='row backup-steps'>
                                <div className='col-lg-1 col-md-1 col-xs-1 text-center number'>
                                    <p className='text-center'><span>3</span></p>
                                </div>
                                <div className='col-lg-11 col-md-11 col-xs-11'>
                                    <p>After selecting the folders you want to backup, click on the three dots on the top-right corner of the interface and select "Export Emails" from the drop-down menu. BlueMail will create a file with all the selected emails in a format that can be easily imported into other email clients.</p>
                                </div>
                            </div>
                            <div className='row backup-steps'>
                                <div className='col-lg-1 col-md-1 col-xs-1 text-center number'>
                                    <p className='text-center'><span>4</span></p>
                                </div>
                                <div className='col-lg-11 col-md-11 col-xs-11'>
                                    <p>Once BlueMail has finished exporting your emails, you need to save the exported file. You can save the file on your desktop or an external storage device. Make sure to name the file appropriately and store it in a safe location.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row pt-120 pb-100 m-rev'>
                        <div className='col-lg-6 col-md-6 col-xs-12 m-auto'>
                            <img src={ImportImg} alt='import' />
                        </div>
                        <div className='col-lg-6 col-md-6 col-xs-12 m-auto'>
                            <h2>How to <span>import</span> your emails to BlueMail</h2>
                            <p>To import your emails, open BlueMail on your desktop and select the email account you want to import the emails to. You can do this by clicking on the email account name in the left-hand panel of the BlueMail interface. Next, click on the three dots on the top-right corner of the interface and select "Import Emails" from the drop-down menu. Select an existing backup file and follow the instructions to import your emails.</p>
                        </div>                    
                    </div>
                </div>
            </div>
            <Footer />
        </Layout>
    )
}

export default Backup

export const query = graphql`
query BackupPageQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`
